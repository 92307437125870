import React from 'react';
import GlobalStyles from './GlobalStyles';

import Members from './Page/Members';

function App() {
  const userLang = navigator.language || navigator.userLanguage;

  const [ lang, setLang ] = React.useState( userLang === 'ko-KR' ? 'ko' : 'en' ); // ko | en

  const changeLanguage = ( language ) => {
    setLang( language );
  };

  const languageHandler = {
    lang,
    changeLanguage,
  };

  return (
    <div>
      <GlobalStyles />
      <Members languageHandler={languageHandler} />
    </div>
  );
}

export default App;
